// http://ionicframework.com/docs/theming/global
@use "./theme/variables.scss";
@use "swiper/scss";
@use "swiper/scss/pagination";
@use "swiper/scss/navigation";
@use "swiper/scss/mousewheel";
@use "./node_modules/ngx-sharebuttons/themes/default.scss";

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@ionic/angular/css/ionic-swiper.css";

@import "~@angular/cdk/overlay-prebuilt.css";

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextLight.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextLight.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextSemiBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextSemiBold.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextSemiBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextSemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakText";
  src: url('./assets/fonts/NeuePlakTextBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakTextBold.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('./assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('./assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('./assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "NeuePlakCompressed";
  src: url('./assets/fonts/NeuePlakCompressedBold.woff2') format('woff2'), url('./assets/fonts/NeuePlakCompressedBold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

html, 
body {
  color: var(--ion-color-dark);
  --color: var(--ion-color-dark);
  --ion-text-color: var(--ion-color-dark);
}

a {
  outline: none;
}

ion-input, ion-textarea, ion-select, ion-datetime {
  & *::placeholder {
    --placeholder-color: var(--ion-color-grey-600);
  }
}

ion-button {
  font-weight: 700;
  position: relative;
  font-family: 'NeuePlakText';
  --padding-top: 4px;
  text-transform: capitalize;

  &::part(native) {
    text-decoration: none;
  }

  &::before {
    content: "";
    border: var(--border-width-hover) solid transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: all 150ms;
  }

  &:hover,
  &.ion-focused {
    text-decoration: underline;

    &::before {
      border: var(--border-width-hover) solid var(--border-color-hover);
      border-radius: var(--border-radius, 0);
      bottom: -4px;
      left: -4px;
      right: -4px;
      top: -4px;
    }
  }

  &[disabled] {
    --color: var(--ion-color-dark-on-disabled) !important;
  }

  &.no-underline {
    ::part(label) {
      text-decoration: none !important;
    }
  }
}

ion-button.button-large  {
  font-family: 'NeuePlakCompressed';
  text-transform: uppercase;
  
  &::part(native) {
    line-height: 1.4em;
    font-size: 36px;
  }
}

/* *******************************************************************
 * ION-BUTTON CLEAR
 * ******************************************************************/
ion-button.button-clear {
  /* Default is the same as primary */
  --background: none;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --border-color: transparent;
  --border-color-hover: transparent;
  --border-width-hover: 0;
  --border-radius: 0;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: none;
  --color: var(--ion-color-primary);
  --color-activated: var(--ion-color-blue-900);
  --color-focused: var(--ion-color-blue-900);
  --color-hover: var(--ion-color-blue-900);

  &.with-hover {
    --border-color-hover: var(--ion-color-primary);
    --border-width-hover: 2px;
    --color-activated: var(--ion-color-primary);
    --color-focused: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
  }
  &.with-hover:hover,
  &.with-hover.ion-focused {
    --border-color: var(--ion-color-primary);
  }

  &:has([slot="icon-only"]) {
    --background-focused-opacity: .2;
    --background-hover-opacity: .1;
    --border-radius: 50%;
  }

  &.btn-primary {
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-blue-900);
    --color-focused: var(--ion-color-blue-900);
    --color-hover: var(--ion-color-blue-900);
    &.with-hover {
      --border-color-hover: var(--ion-color-primary);
      --color-activated: var(--ion-color-primary);
      --color-focused: var(--ion-color-primary);
      --color-hover: var(--ion-color-primary);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-primary);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-primary);
        --background-focused: var(--ion-color-primary);
        --background-hover: var(--ion-color-primary);
      }
    }
  }

  &.btn-secondary {
    --color: var(--ion-color-secondary);
    --color-activated: var(--ion-color-yellow-900);
    --color-focused: var(--ion-color-yellow-900);
    --color-hover: var(--ion-color-yellow-900);
    &.with-hover {
      --border-color-hover: var(--ion-color-secondary);
      --color-activated: var(--ion-color-secondary);
      --color-focused: var(--ion-color-secondary);
      --color-hover: var(--ion-color-secondary);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-secondary);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-secondary);
        --background-focused: var(--ion-color-secondary);
        --background-hover: var(--ion-color-secondary);
      }
    }
  }

  &.btn-danger {
    --color: var(--ion-color-danger);
    --color-activated: var(--ion-color-red-900);
    --color-focused: var(--ion-color-red-900);
    --color-hover: var(--ion-color-red-900);
    &.with-hover {
      --border-color-hover: var(--ion-color-danger);
      --color-activated: var(--ion-color-danger);
      --color-focused: var(--ion-color-danger);
      --color-hover: var(--ion-color-danger);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-danger);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-danger);
        --background-focused: var(--ion-color-danger);
        --background-hover: var(--ion-color-danger);
      }
    }
  }

  &.btn-success {
    --color: var(--ion-color-success);
    --color-activated: var(--ion-color-green-900);
    --color-focused: var(--ion-color-green-900);
    --color-hover: var(--ion-color-green-900);
    &.with-hover {
      --border-color-hover: var(--ion-color-success);
      --color-activated: var(--ion-color-success);
      --color-focused: var(--ion-color-success);
      --color-hover: var(--ion-color-success);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-success);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-success);
        --background-focused: var(--ion-color-success);
        --background-hover: var(--ion-color-success);
      }
    }
  }

  &.btn-dark {
    --color: var(--ion-color-dark);
    --color-activated: var(--ion-color-grey-800);
    --color-focused: var(--ion-color-grey-800);
    --color-hover: var(--ion-color-grey-800);
    &.with-hover {
      --border-color-hover: var(--ion-color-dark);
      --color-activated: var(--ion-color-dark);
      --color-focused: var(--ion-color-dark);
      --color-hover: var(--ion-color-dark);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-dark);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-dark);
        --background-focused: var(--ion-color-dark);
        --background-hover: var(--ion-color-dark);
      }
    }
  }

  &.btn-medium {
    --color: var(--ion-color-medium);
    --color-activated: var(--ion-color-grey-600);
    --color-focused: var(--ion-color-grey-600);
    --color-hover: var(--ion-color-grey-600);
    &.with-hover {
      --border-color-hover: var(--ion-color-medium);
      --color-activated: var(--ion-color-medium);
      --color-focused: var(--ion-color-medium);
      --color-hover: var(--ion-color-medium);
    }
    &.with-hover:hover,
    &.with-hover.ion-focused {
      --border-color: var(--ion-color-medium);
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-medium);
        --background-focused: var(--ion-color-medium);
        --background-hover: var(--ion-color-medium);
      }
    }
  }

  &.btn-light {
    --border-color-hover: transparent;
    --color: var(--ion-color-light);
    --color-activated: var(--ion-color-light);
    --color-focused: var(--ion-color-light);
    --color-hover: var(--ion-color-light);
    &:hover,
    &.ion-focused {
      --border-color: transparent;
      --border-color-hover: transparent;

      &::after {
        border: none !important;
      }
    }
    &:has([slot="icon-only"]) {
      &:hover,
      &.ion-focused {
        --background-activated: var(--ion-color-light);
        --background-focused: var(--ion-color-light);
        --background-hover: var(--ion-color-light);
      }
    }
  }

  &.btn-link {
    font-weight: 400;
    text-decoration: underline;
    text-transform: none;

    &:hover,
    &.ion-focused {
      opacity: .7;
    }
  }
}

/* *******************************************************************
 * ION-BUTTON SOLID
 * ******************************************************************/
ion-button.button-solid {
  --background: var(--ion-color-light);
  --background-focused-opacity: 1;
  --background-hover-opacity: 1;
  --border-radius: 0;
  --border-color: transparent;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: none;
  --border-color-hover: var(--ion-color-primary);
  --border-width-hover: 2px;
  --color: var(--ion-color-primary);

  &:hover,
  &.ion-focused {
    --border-color: var(--ion-color-primary);
  }

  &.btn-primary {
    --background: var(--ion-color-primary);
    --background-focused: var(--ion-color-primary);
    --background-hover: var(--ion-color-primary);
    --border-color-hover: var(--ion-color-primary);
    --color: var(--ion-color-secondary);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-primary);
    }
  }

  &.btn-secondary {
    --background: var(--ion-color-secondary);
    --background-focused: var(--ion-color-secondary);
    --background-hover: var(--ion-color-secondary);
    --border-color-hover: var(--ion-color-secondary);
    --color: var(--ion-color-primary);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-secondary);
    }
  }

  &.btn-danger {
    --background: var(--ion-color-danger);
    --background-focused: var(--ion-color-danger);
    --background-hover: var(--ion-color-danger);
    --border-color-hover: var(--ion-color-danger);
    --color: var(--ion-color-light);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-danger);
    }
  }

  &.btn-success {
    --background: var(--ion-color-success);
    --background-focused: var(--ion-color-success);
    --background-hover: var(--ion-color-success);
    --border-color-hover: var(--ion-color-success);
    --color: var(--ion-color-light);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-success);
    }
  }

  &.btn-dark {
    --background: var(--ion-color-dark);
    --background-focused: var(--ion-color-dark);
    --background-hover: var(--ion-color-dark);
    --border-color-hover: var(--ion-color-dark);
    --color: var(--ion-color-light);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-dark);
    }
  }

  &.btn-medium {
    --background: var(--ion-color-medium);
    --background-focused: var(--ion-color-medium);
    --background-hover: var(--ion-color-medium);
    --border-color-hover: var(--ion-color-medium);
    --color: var(--ion-color-light);
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-medium);
    }
  }

  &.ion-color-transparent {
    --border-color-hover: transparent;
    &:hover,
    &.ion-focused {
      --border-color: var(--ion-color-light-tint);
    }
  }

  &[disabled] {
    --background: var(--ion-color-grey-100) !important;
    opacity: 1 !important;
  }
}

/* *******************************************************************
 * ION-BUTTON OUTLINE
 * ******************************************************************/
ion-button.button-outline {
  /* Default is the same as primary */
  --background: var(--ion-color-light);
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --border-color: var(--ion-color-primary);
  --border-color-hover: var(--ion-color-primary);
  --border-width-hover: 2px;
  --border-radius: 0;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: none;
  --color: var(--ion-color-primary);

  &.btn-primary {
    --border-color: var(--ion-color-primary);
    --border-color-hover: var(--ion-color-primary);
    --color: var(--ion-color-primary);
  }

  &.btn-secondary {
    --border-color: var(--ion-color-secondary);
    --border-color-hover: var(--ion-color-secondary);
    --color: var(--ion-color-secondary);
  }

  &.btn-danger {
    --border-color: var(--ion-color-danger);
    --border-color-hover: var(--ion-color-danger);
    --color: var(--ion-color-danger);
  }

  &.btn-success {
    --border-color: var(--ion-color-success);
    --border-color-hover: var(--ion-color-success);
    --color: var(--ion-color-success);
  }

  &.btn-dark {
    --border-color: var(--ion-color-dark);
    --border-color-hover: var(--ion-color-dark);
    --color: var(--ion-color-dark);
  }

  &.btn-medium {
    --border-color: var(--ion-color-medium);
    --border-color-hover: var(--ion-color-medium);
    --color: var(--ion-color-medium);
  }

  &[disabled] {
    --border-color: var(--ion-color-grey-100) !important;
  }
}

ion-button.button-round,
ion-button.btn-round {
  --border-radius: 64px !important;
}

ion-button.button-round.button-small,
ion-button.btn-round.button-small {
  &.in-toolbar {
    width: 36px !important;
    height: 36px !important;
  }
}

.button-small {
  --padding-top: 1px;
}

ion-toolbar[mode="ios"] > ion-buttons > ion-button {
  --border-style: none;
}

/* *******************************************************************
 * ION-RADIO
 * ******************************************************************/
ion-radio {
  // Container of the checkbox
  &::part(container) {
    border: 1px solid var(--ion-color-dark);
  } 

  &.radio-checked::part(container) {
    background: var(--ion-color-primary);
  }
  // Check or Circle of checkbox
  &::part(mark) {
    background: var(--ion-color-secondary);
    height: 8px;
    width: 8px;
  }
}

/* *******************************************************************
 * ION-CHECKBOX focus indicators
 * ******************************************************************/
ion-checkbox {
  --border-radius: 0;
  --border-color: var(--ion-color-dark);
  --checkbox-background-checked: var(--ion-color-primary);
  --checkmark-color: var(--ion-color-secondary);

  &.checkbox-checked::part(container) {
    border: 1px solid var(--ion-color-dark); 
    padding: 2px;
  }
}

ion-checkbox::part(label) {
  flex-grow: 1;
  position: relative;
  overflow: visible;
}

ion-checkbox:focus::part(label)::after,
ion-checkbox:hover::part(label)::after {
  content: "";
  background-color: var(--ion-color-grey-400);
  border-radius: 50%;
  opacity: 0.2;
  display: block;
  width: 36px;
  height: 36px;
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -43px; // 1/2 of inline start + the width of the circle
}

ion-checkbox.checkbox-label-placement-end:focus::part(label)::after,
ion-checkbox.checkbox-label-placement-end:hover::part(label)::after {
  left: -43px;
}

hr {
  background: var(--ion-color-medium);
}

ion-grid {
  min-height: 100%;
}

.plt-mobile {
  app-profile, app-post-list, app-category-list, app-home {
    ion-content {
      --padding-bottom: 100px !important;
    }
  }
}

ion-menu,
ion-menu ion-content {
  --background: var(--ion-color-dark);
}

ion-menu ion-toolbar {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

.ion-text-nowrap {
  * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.text-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollable {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
}

.flex-column { flex-direction: column; }
.flex-wrap   { flex-wrap: wrap; }

.flex-row-reverse    { flex-direction: row-reverse; }
.flex-column-reverse { flex-direction: column-reverse; }

.items-start    { align-items: flex-start; }
.items-end      { align-items: flex-end; }
.items-center   { align-items: center; }
.items-baseline { align-items: baseline; }
.items-stretch  { align-items: stretch; }

.flex-1       { flex: 1 1 0%; }
.flex-auto    { flex: 1 1 auto; }
.flex-initial { flex: 0 1 auto; }
.flex-none    { flex: none; }

.vertical-align-top {
  vertical-align: top;
}

.inline {
  display: inline;
}

.fullscreen {
  width: 100% !important;
  height: 100% !important;
}

.scroll {
  overflow-y: auto;
}

.swal2-container {
  color: var(--ion-text-color);
  font-weight: bold;

  .swal2-popup {
    background: var(--ion-color-light);
  }

  .swal2-title,
  .swal2-html-container {
    color: var(--ion-text-color);
  }

  .swal2-title {
    font-family: "NeuePlakCompressed", sans-serif;
    font-weight: bold;
    font-size: 50px;
  }

  .swal2-html-container {
    font-family: "NeuePlakText", sans-serif;
  }

  .swal2-textarea {
    box-shadow: none !important;
    border-color: var(--ion-color-light-shade);
    color: var(--ion-text-color);

    &:focus {
      border-color: var(--ion-color-medium-tint);
    }
  }

  .swal2-radio {
    display: block !important;
    background: var(--ion-color-light);

    label {
      font-size: 16px;
      display: block;
      margin: 16px 0;
      text-align: initial;
    }
  }

  .swal2-actions {
    text-align: center;
  }

  .swal2-styled {
    &.swal2-confirm {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-secondary);
      font-weight: bold;
      border-radius: 0;
      text-decoration: none;
      position: relative;

      &::before {
        content: "";
        border: 2px solid transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }

      &:hover, 
      &:focus, 
      &:focus-within {
        text-decoration: underline;

        &::before {
          content: "";
          border: 2px solid var(--ion-color-primary);
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          bottom: -4px;
          left: -4px;
          right: -4px;
          top: -4px;
        }
      }
    }

    &.swal2-cancel {
      background-color: var(--ion-color-medium);
      color: var(--ion-color-medium-contrast);
      font-weight: bold;
      border-radius: 0;
      text-decoration: none;
      position: relative;

      &::before {
        content: "";
        border: 2px solid transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
      
      &:hover,
      &:focus,
      &:focus-within {
        text-decoration: underline;
    
        &::before {
          content: "";
          border: 2px solid var(--ion-color-medium);
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          bottom: -4px;
          left: -4px;
          right: -4px;
          top: -4px;
        }
      }
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.block {
  display: block !important;
}

.bold {
  font-weight: bold !important;
}

.padding-small {
  padding: 4px !important;
}

.padding-medium {
  padding: 8px !important;
}

.padding-horizontal-medium {
  padding: 0 8px !important;
}

.margin-medium {
  margin: 8px !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.column-center {
  display: flex;
  justify-content: center;
}

.font-neueplaktext {
  font-family: "NeuePlakText", sans-serif; 
}

.font-neueplakcompressed {
  font-family: "NeuePlakCompressed", sans-serif;
  font-size: 18px;
  line-height: 90%;
}

.text-xs {
  font-size: 10px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 14px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-slarge {
  font-size: 18px !important;
}

.text-mlarge {
  font-size: 21px !important;
}

.text-large {
  font-size: 24px !important;
}

.text-headline {
  font-size: 26px !important;
}

.text-xlarge {
  font-size: 36px !important;
}

.text-xxlarge {
  font-size: 48px !important;
}

.text-xxxlarge {
  font-size: 64px;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.border {
  border: 1px solid #d8d8d8 !important;
}

.radius {
  border-radius: 4px !important;
}

.radius-top {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.radius-bottom {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.disabled {
  opacity: 0.5 !important;
}

.menu-toolbar {
  padding: 0;
}

.loading-wrapper {
  border: 4px solid var(--ion-color-light);
}

.scroll {
  height: 100%;
}

.shadow {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}

.horizontal-line {
  height: 1px;
  background-color: #ccc;
}

.relative {
  position: relative !important;
}

.plt-desktop {
  .link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .hover:hover {
    --background: var(--ion-color-light-tint);
    background: var(--ion-color-light-tint);
  }
}

.line {
  height: 1px;
  background: var(--ion-color-primary);
}

.transparent {
  background: transparent !important;
  --background: transparent !important;
}

ion-avatar img {
  border: 2px solid var(--ion-color-light);
  box-shadow: 0px 1px 3px 1px rgb(0, 0, 0, .1);
}

.light-bg {
  background: var(--ion-color-light);
}

.no-margin {
  margin: 0 !important;
}

.ellipsis {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.center-transform {
  margin-left: 50%;
  transform: translateX(-50%);
}

.ng-lazyloaded,
.fade-in {
  animation: fadeIn;
  animation-duration: 400ms;
}

.fade-out {
  animation: fadeOut;
  animation-duration: 200ms;
}
.card-content {
  padding: 4px 8px !important;
}

// Info Window
.si-content-wrapper {
  border: none !important;
}

.info-window-wrapper {
  .si-pointer-bg-top {
    border-top-color: var(--ion-color-white);
  }
}

.si-pointer-border-top {
  border-top-color: transparent !important;
}

.si-pointer-top {
  border-width: 14px !important;
}

::-webkit-input-placeholder {
  font-size: 12px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Desktop

ion-header.ion-text-center, 
ion-header ion-toolbar {
  margin: auto;
  // --ion-color-base: var(--ion-color-tertiary-shade) !important;
}

ion-header,
ion-title {
  font-family: "NeuePlakCompressed", sans-serif;
  font-weight: 600;
}

ion-title {
  font-size: 32px;
}

@media (min-width: 0px) {
  .hide-xs-up {
    display: none;
  }

  .show-xs-up {
    display: block;
  }
}

@media (min-width: 576px) {
  .hide-sm-up {
    display: none;
  }

  .show-sm-up {
    display: block;
  }
}

@media (min-width: 768px) {
  .hide-md-up {
    display: none;
  }

  .show-md-up {
    display: block;
  }

  ion-tab-bar.show-lg-up {
    display: flex;
  }
}

@media (min-width: 992px) {
  .hide-lg-up {
    display: none;
  }

  .show-lg-up {
    display: block;
  }
}

@media (min-width: 1200px) {
  .hide-xl-up {
    display: none;
  }

  .show-xl-up {
    display: block;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .hide-xs-only {
    display: none;
  }

  .show-xs-only {
    display: block;
  }

  ion-modal {
    &.pay-modal {
      --border-radius: 12px;
      --width: 90%;
      --height: 80%;
    }
  }

  /*
  .tabs-inner {
    min-height: 100%;
  }
  */
}

@media (min-width: 576px) and (max-width: 767px) {
  .hide-sm-only {
    display: none;
  }

  .show-sm-only {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .show-md-only {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .show-lg-only {
    display: block;
  }
}

$grid-breakpoints-lg: 992px;

@media (min-width: $grid-breakpoints-lg) {
  ion-header ion-toolbar[fixed],
  ion-tabs ion-tab-bar[fixed],
  ion-footer ion-toolbar[fixed] {
    margin: 0;
    padding-right: calc((100% - #{$grid-breakpoints-lg}) / 2);
    padding-left: calc((100% - #{$grid-breakpoints-lg}) / 2);
  }

  ion-tabs ion-tab-bar {
    justify-content: flex-start;

    ion-tab-button {
      justify-content: flex-start;
    }
  }

  ion-header ion-toolbar {
    /* --min-height: 70px; */

    .logo {
      height: 50px;
    }
  }
}

.html-content {
  line-height: 1.5;
  text-align: justify;

  * {
    color: var(--ion-text-color);
  }

  img,
  video {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
  }
}

.plt-desktop {
  ion-card,
  .card {
    cursor: pointer;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

app-place-card {
  .rating {
    .br-stars {
      margin: 0 !important;
    }
    .br-unit-inner {
      --font-size: 12px;
      width: 12px !important;
      height: 12px !important;
    }
  }
}

app-place-detail,
app-review-list,
app-review-user-list,
app-map {
  .rating {
    .br-stars {
      margin: 8px 0 !important;
    }
    .br-unit-inner {
      --font-size: 16px;
      width: 16px !important;
      height: 16px !important;
    }
  }
}

app-review-add {
  .rating {
    .br-stars {
      margin: 8px 0 !important;
    }

    .br-units {
      justify-content: space-between;
    }

    .br-unit-inner {
      --font-size: 48px;
      width: 48px !important;
      height: 48px !important;
    }
  }
}

ion-toast::part(container) {
  background: var(--ion-color-light);
  color: var(--ion-color-base);
}

.ios {
  ion-toolbar {
    --min-height: 60px;
  }

  .tabs-bottom {
    transform: translateY(-50px) !important;
  }

  .select-popover {
    --width: 230px;
  }
}

.md {
  .tabs-bottom {
    transform: translateY(-56px) !important;
  }
}

ion-searchbar {
  pointer-events: all !important;
  opacity: 1 !important;
  --border-radius: 0 !important;
  &.text-small {
    .searchbar-input {
      font-size: 12px !important;
    }
  }

  .searchbar-input {
    font-weight: bold !important;
    text-overflow: ellipsis !important;
  }

  input {
    opacity: 1 !important;
  }
}

.select-popover .ion-select-option-color-secondary {
  --color: var(--ion-color-secondary-shade);
}
.select-popover .ion-select-option-color-primary {
  --color: var(--ion-color-primary);
}

.logo {
  display: flex;
  align-items: center;
  margin-inline-end: 16px;

  img {
    display: block;
    height: 35px;
  }
}

div.ion-activatable {
  position: relative;
  overflow: hidden;
  --ripple-color: rgba(var(--ion-color-dark-rgb), 0.5);
}

.StripeElement {
  background: var(--ion-color-white) !important;
  border: 1px solid var(--ion-color-light-shade);
  color: var(--ion-text-color) !important;
  border-radius: 8px;
  padding: 14px !important;
}

ion-searchbar.text-medium {
  input {
    font-size: 13px !important;
  }
}

.select-popover {
  ion-item {
    --border-color: var(--ion-color-light-tint);

    ion-label {
      white-space: normal !important;
    }
  }

  .select-interface-option {
    --background-focused: inherit;
    --background-hover: var(--ion-color-light-tint);
    --background-hover-opacity: 0.4;
  }
  .item-radio-checked {
    --background: rgba(var(--ion-color-light-rgb), 1.0);
    font-weight: bold;
  }
}

ion-modal empty-view {
  height: 100% !important;
  padding-bottom: 0 !important;
}

.swiper {
  --bullet-background: var(--ion-color-light);
  --bullet-background-active: var(--ion-color-primary);
  --swiper-navigation-color: var(--ion-color-primary);
  --swiper-pagination-bullet-inactive-opacity: 0.4;

  .swiper-pagination-bullet {
    border: 1px solid var(--ion-color-primary);
  }

  .swiper-pagination-progressbar {
    position: relative;
    top: 240px;
  }

  
}

ion-alert {
  .alert-wrapper {
    .select-interface-option[aria-checked="true"] .alert-checkbox-icon {
      background-color: var(--ion-color-primary);
      border-color: var(--ion-color-medium);
    }

    .alert-button {
      color: var(--ion-color-primary);

      &.alert-button-role-cancel {
        color: var(--ion-color-dark);
      }
    }
  }
}

.cupertino-pane-wrapper {
  .pane {
    --cupertino-pane-background: var(--ion-color-light);
  }
  .cupertino-pane {
    margin: 20px;
  }

  ion-list {
    background: transparent;
  }
}

web-social-share {
  --web-social-share-action-sheet-group-background: var(--ion-color-light);
}

ion-refresher {
  z-index: 10;
  ion-spinner,
  ion-icon,
  .refresher-pulling-icon {
    color: var(--ion-text-color) !important;
  }
}

.custom-link {
  cursor: pointer;
  pointer-events: all;
  text-decoration: underline;

  &:focus {
    color: var(--ion-color-primary);
  }
}

html.ios ion-modal .ion-page {
  border-radius: 0;
}

ion-input {
  text-overflow: ellipsis;
}

.bg-accent-subtle {
  --background: #ECEEFE;
}
.bg-mint-100 {
  --background: #F1FEEC;
}
.bg-yellow-200 {
  --background: #FFF1D7;
}
.pref-icon {
  width: 34px;
  height: 34px;
}
 
/* *****************************************************************
 * MARGIN SETTINGS
 * ****************************************************************/
.m0			    {margin: 0 !important;}
.mt0		    {margin-top: 0 !important;}
.mr0		    {margin-right: 0 !important;}
.mb0		    {margin-bottom: 0 !important;}
.ml0		    {margin-left: 0 !important;}
.mh0		    {margin-right: 0 !important; margin-left: 0 !important;}
.mv0		    {margin-top: 0 !important; margin-bottom: 0 !important;}
.m			    {margin: 8px !important;}
.mt			    {margin-top: 8px !important;}
.mr			    {margin-right: 8px !important;}
.mb			    {margin-bottom: 8px !important;}
.ml			    {margin-left: 8px !important;}
.mh			    {margin-right: 8px !important; margin-left: 8px !important;}
.mv			    {margin-top: 8px !important; margin-bottom: 8px !important;}
.m-			    {margin: 4px !important;}
.mt-		    {margin-top: 4px !important;}
.mr-		    {margin-right: 4px !important;}
.mb-		    {margin-bottom: 4px !important;}
.ml-		    {margin-left: 4px !important;}
.mh-		    {margin-right: 4px !important; margin-left: 4px !important;}
.mv-		    {margin-top: 4px !important; margin-bottom: 4px !important;}
.m\+		    {margin: 16px !important;}
.mt\+		    {margin-top: 16px !important;}
.mr\+		    {margin-right: 16px !important;}
.mb\+		    {margin-bottom: 16px !important;}
.ml\+		    {margin-left: 16px !important;}
.mh\+		    {margin-right: 16px !important; margin-left: 16px !important;}
.mv\+		    {margin-top: 16px !important; margin-bottom: 16px !important;}
.m\+\+		  {margin: 24px !important;}
.mt\+\+		  {margin-top: 24px !important;}
.mr\+\+		  {margin-right: 24px !important;}
.mb\+\+		  {margin-bottom: 24px !important;}
.ml\+\+		  {margin-left: 24px !important;}
.mh\+\+		  {margin-right: 24px !important; margin-left: 24px !important;}
.mv\+\+		  {margin-top: 24px !important; margin-bottom: 24px !important;}
.m\+\+\+	  {margin: 32px !important;}
.mt\+\+\+	  {margin-top: 32px !important;}
.mr\+\+\+	  {margin-right: 32px !important;}
.mb\+\+\+	  {margin-bottom: 32px !important;}
.ml\+\+\+	  {margin-left: 32px !important;}
.mh\+\+\+	  {margin-right: 32px !important; margin-left: 32px !important;}
.mv\+\+\+	  {margin-top: 32px !important; margin-bottom: 32px !important;}
.m\+\+\+\+	{margin: 40px !important;}
.mt\+\+\+\+	{margin-top: 40px !important;}
.mr\+\+\+\+	{margin-right: 40px !important;}
.mb\+\+\+\+	{margin-bottom: 40px !important;}
.ml\+\+\+\+	{margin-left: 40px !important;}
.mh\+\+\+\+	{margin-right: 40px !important; margin-left: 40px !important;}
.mv\+\+\+\+	{margin-top: 40px !important; margin-bottom: 40px !important;}
 
/* *****************************************************************
 * PADDING SETTINGS
 * ****************************************************************/
.p0			      {padding: 0 !important;}
.pt0		      {padding-top: 0 !important;}
.pr0		      {padding-right: 0 !important;}
.pb0		      {padding-bottom: 0 !important;}
.pl0		      {padding-left: 0 !important;}
.ph0		      {padding-right: 0 !important; padding-left: 0 !important;}
.pv0		      {padding-top: 0 !important; padding-bottom: 0 !important;}
.p			      {padding: 8px !important;}
.pt			      {padding-top: 8px !important;}
.pr			      {padding-right: 8px !important;}
.pb			      {padding-bottom: 8px !important;}
.pl			      {padding-left: 8px !important;}
.ph			      {padding-right: 8px !important; padding-left: 8px !important;}
.pv			      {padding-top: 8px !important; padding-bottom: 8px !important;}
.p-			      {padding: 4px !important;}
.pt-		      {padding-top: 4px !important;}
.pr-		      {padding-right: 4px !important;}
.pb-		      {padding-bottom: 4px !important;}
.pl-		      {padding-left: 4px !important;}
.ph-		      {padding-right: 4px !important; padding-left: 4px !important;}
.pv-		      {padding-top: 4px !important; padding-bottom: 4px !important;}
.p\+		      {padding: 16px !important;}
.pt\+		      {padding-top: 16px !important;}
.pr\+		      {padding-right: 16px !important;}
.pb\+		      {padding-bottom: 16px !important;}
.pl\+		      {padding-left: 16px !important;}
.ph\+		      {padding-right: 16px !important; padding-left: 16px !important;}
.pv\+		      {padding-top: 16px !important; padding-bottom: 16px !important;}
.p\+\+		    {padding: 24px !important;}
.pt\+\+		    {padding-top: 24px !important;}
.pr\+\+		    {padding-right: 24px !important;}
.pb\+\+		    {padding-bottom: 24px !important;}
.pl\+\+		    {padding-left: 24px !important;}
.ph\+\+		    {padding-right: 24px !important; padding-left: 24px !important;}
.pv\+\+		    {padding-top: 24px !important; padding-bottom: 24px !important;}
.p\+\+\+	    {padding: 32px !important;}
.pt\+\+\+	    {padding-top: 32px !important;}
.pr\+\+\+	    {padding-right: 32px !important;}
.pb\+\+\+	    {padding-bottom: 32px !important;}
.pl\+\+\+	    {padding-left: 32px !important;}
.ph\+\+\+	    {padding-right: 32px !important; padding-left: 32px !important;}
.pv\+\+\+	    {padding-top: 32px !important; padding-bottom: 32px !important;}
.p\+\+\+\+	  {padding: 40px !important;}
.pt\+\+\+\+	  {padding-top: 40px !important;}
.pr\+\+\+\+	  {padding-right: 40px !important;}
.pb\+\+\+\+	  {padding-bottom: 40px !important;}
.pl\+\+\+\+	  {padding-left: 40px !important;}
.ph\+\+\+\+	  {padding-right: 40px !important; padding-left: 40px !important;}
.pv\+\+\+\+	  {padding-top: 40px !important; padding-bottom: 40px !important;}
.pt\+\+\+\+\+	{padding-top: 48px !important;}
.pr\+\+\+\+\+	{padding-right: 48px !important;}
.pb\+\+\+\+\+	{padding-bottom: 48px !important;}
.pl\+\+\+\+\+	{padding-left: 48px !important;}
.ph\+\+\+\+\+	{padding-right: 48px !important; padding-left: 48px !important;}
.pv\+\+\+\+\+	{padding-top: 48px !important; padding-bottom: 48px !important;}
 
/* *****************************************************************
 * OPACITY
 * ****************************************************************/
.opacity-90{opacity:0.9;}
.opacity-80{opacity:0.8;}
.opacity-70{opacity:0.7;}
.opacity-60{opacity:0.6;}
.opacity-50{opacity:0.5;}
.opacity-40{opacity:0.4;}
.opacity-30{opacity:0.3;}
.opacity-20{opacity:0.2;}
.opacity-10{opacity:0.1;}
.semibold {
  font-weight: 600;
}

/*
ion-back-button {
  width: 44px;
}
*/

ion-toolbar.mini-bar {
  min-height: 4px;
  padding: 0 60px;
  --min-height: auto;
}

ion-toolbar ion-title small {
  display: block;
  font-weight: 100;
  font-family: 'NeuePlakCompressed';
}

.iti input,
.iti input:focus {
  border: none;
  outline: none;
}

.card-content-md.has-inner-html p,
.card-content-ios.has-inner-html p {
  font-size: inherit !important;
}

.card-modal {
  --background: #0000005c;
  
  ion-content {
    --background: transparent;
  }
}

.modal-fullscreen {
  --min-width: 100vw;
}

.modal-fullheight, .modal-fullscreen {
  --height: 100%;
  --min-height: 100%;
  --border-radius: 0 !important;
  --border-style: none !important;
}

.modal-long-content {
  @media (min-width: 768px) {
    --height: 90% !important;
  }
}

@media (min-width: 768px) {
  .modal-signup {
    --height: 84vh;
    --min-height: min(692px, 84vh);
    --max-height: min(692px, 84vh);
  }
}

.ios .modal-fullscreen, .ios .modal-fullheight { .modal-wrapper { top: 26px; } }

.modal-no-border {
  --border-width: 0 !important;
  --border-style: none !important;
}

.modal-no-border-radius {
  --border-radius: 0 !important;
}

.modal-content-height {
  --height: fit-content;
  --width: 320px;
}

.small-modal-popup {
  --background: rgba(0,0,0,.5);
  & ion-card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    min-width: 300px;
    width: 90vw;
    max-width: 500px;
  }
}

.modal-never-fullscreen {
  @extend .small-modal-popup;
  &::part(content) {
    border-radius: 4px;
    margin: 32px;
    --height: fit-content;
  }
}

.ion-placeholder-normal {
  & *::placeholder {
    font-size: 16px !important;
  }
}
.ion-placeholder-medium {
  & *::placeholder {
    font-size: 14px !important;
  }
}

ion-popover.invoice-popover {
  &::part(content) {
    max-width: 90px;
  }
}

/* Removes password show / hide on Edge, IE, etc. */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* ************************************************************
 * Ionic v7
 * - <ion-input>, <ion-textarea>, and <ion-select> stacked labels
 * - remove surrounding <ion-item> and add input attributes to
 *   <ion-input>, <ion-textarea>, and <ion-select> directly
 * ************************************************************/
$ionic7InputPaddingBottom: 4px;
$ionic7InputPaddingTop: 32px;
$ionic7LabelTranslateX: -15px;
$ionic7LabelTranslateY: -36px;
$ionic7InputSpacing: 16px;

ion-select {
  --border-color: var(--ion-color-light-shade);
  &:hover {
    --border-color: var(--ion-color-medium-shade);
  }
  &.ion-touched.ion-invalid {
    --border-color: var(--ion-color-danger);
    --highlight-color-invalid: var(--ion-color-danger);
  }
  &.ion-focused.ion-valid {
    --border-color: var(--ion-color-primary);
  }

  &::part(label) {
    text-wrap: wrap;
    white-space: wrap;
  }
}

ion-input, ion-textarea, ion-select, ion-searchbar {
  --highlight-color-focused: var(--ion-color-primary) !important;
  --highlight-color-valid: var(--ion-color-primary) !important;
  --highlight-color-invalid: var(--ion-color-danger) !important;
  --border-color: var(--ion-color-medium);
  --border-radius: 0 !important;
}

/* Outlined inputs/textareas without stacked labels */
.input-fill-outline.input-label-placement-start,
.textarea-fill-outline.textarea-label-placement-start  {
  --border-color: var(--ion-color-medium);
  margin: 0;

  &:hover {
    --border-color: var(--ion-color-medium-shade);
  }
  &.ion-invalid {
    --border-color: var(--ion-color-danger);
  }
  &.ion-focused.ion-valid {
    --border-color: var(--ion-color-primary);
  }

  & .input-outline-container,
  & .textarea-outline-container {
    background-color: var(--ion-color-light);
  }

  & .input-outline-start,
  & .input-outline-notch,
  & .input-outline-end,
  & .textarea-outline-start,
  & .textarea-outline-notch,
  & .textarea-outline-end {
    height: 100%;
  }

  & .input-outline-notch,
  & .textarea-outline-notch {
    border-top: var(--border-width) var(--border-style) var(--border-color) !important;
  }
}

/* Outlined inputs/textareas with stacked labels */
.input-fill-outline.input-label-placement-stacked,
.textarea-fill-outline.textarea-label-placement-stacked,
.select-fill-outline.select-label-placement-stacked {
  --border-color: var(--ion-color-medium);
  --border-radius: 0;
  margin: 0;
  padding-bottom: $ionic7InputPaddingBottom !important;
  padding-top: $ionic7InputPaddingTop !important;

  &.summarized-input {
    padding-top: 16px !important;
    padding-bottom: $ionic7InputPaddingBottom !important;

    & .label-text-wrapper,
    &::part(label) {
      font-size: 12px !important;
      -webkit-transform: translate(-14px, -24px) !important;
      transform: translate(-14px, -24px) !important;
    }

    & input.native-input {
      margin: 0;
      height: inherit;
    }
  }

  &:hover {
    --border-color: var(--ion-color-medium-shade);
  }

  & .input-outline-container,
  & .textarea-outline-container {
    background-color: var(--ion-color-light);
  }

  & .input-outline-notch,
  & .textarea-outline-notch {
    border-top: var(--border-width) var(--border-style) var(--border-color) !important;
  }
  
  & .label-text-wrapper,
  &::part(label) {
    color: var(--ion-color-dark);
    -webkit-transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
    transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
  }

  &.label-bold {
    & .label-text-wrapper,
    &::part(label) {
      font-weight: bold;
    }
  }

  &.required-asterisk {
    & .label-text::after {
      content: ' *';
      color: var(--ion-color-danger);
      font-size: 20px;
      position: absolute;
      right: -14px;
      top: -2px;
    }
  }
}

ion-input.label-bold {
  & .label-text-wrapper,
  &::part(label) {
    color: var(--ion-color-dark);
    font-weight: bold;
    -webkit-transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
    transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
  }
}

::ng-deep .ion-select-max-height {
  .alert-radio-group {
    max-height: 100%;
  }
}

ion-select.label-bold {
  &::part(label) {
    color: var(--ion-color-dark);
    font-weight: bold;
    -webkit-transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
    transform: translate($ionic7LabelTranslateX, $ionic7LabelTranslateY) !important;
  }
}

ion-select.summarized-input {
  margin-bottom: 4px;
  padding-top: 16px !important;
  
  &::part(label) {
    font-size: 14px !important;
    font-weight: bold !important;
    -webkit-transform: translate(-14px, -24px) !important;
    transform: translate(-14px, -24px) !important;
  }
}

/* ************************************************************
 * Ionic v7
 * - Helper style for <ion-input> with a button in the previous "end" slot
 * - When wrapping the new inputs with <ion-item>, it breaks the formatting,
 *   so we need to create a generic wapper DIV and absolute position the button
 * ************************************************************/
div.input-wrapper,
section.input-wrapper {
  position: relative;

  &:has(*[slot="start"]) {
    & input,
    & textarea {
      padding-left: var(--input-start, 48px) !important;
    }
    & [slot="start"] {
      left: var(--slot-start, 16px);
    }
  }

  &:has(*[slot="end"]) {
    & input,
    & textarea {
      padding-right: var(--input-end, 48px) !important;
    }
    & [slot="end"] {
      right: var(--slot-end, 16px);
    }
  }

  &:has(*[slot="start"]),
  &:has(*[slot="end"]) {
    & [slot="start"],
    & [slot="end"] {
      position: absolute;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      top: calc($ionic7InputPaddingTop + var(--slot-top, 16px));
      z-index: 10;

      & ion-icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }

      &[size="small"] {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        top: calc($ionic7InputPaddingTop + var(--slot-top, 18px));
  
        & ion-icon {
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
        }
      }

      &[size="large"] {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        top: calc($ionic7InputPaddingTop + var(--slot-top, 12px));
  
        & ion-icon {
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
        }
      }
    }
  }
}

div.select-wrapper,
section.select-wrapper {
  position: relative;

  > ion-label {
    color: var(--ion-color-dark);
    position: absolute;
    top: -3px;

    &.required-asterisk::after {
      content: ' *';
      color: var(--ion-color-danger);
      font-size: 20px;
      position: absolute;
      right: -14px;
      top: -2px;
    }
  }

  &:has(.select-expanded) {
    ion-label { color: var(--ion-color-primary); }
  }
  &:has(.ion-invalid) {
    ion-label { color: var(--ion-color-danger); }
  }
  &:has(.ion-focused.ion-valid),
  &:has(.select-expanded.ion-valid) {
    ion-label { color: var(--ion-color-primary); }
  }

  > ion-select[fill="outline"] {
    padding-bottom: $ionic7InputPaddingBottom !important;
    padding-top: $ionic7InputPaddingTop !important;

    &:hover {
      --border-color: var(--ion-color-medium-shade);
    }
    &.ion-invalid {
      --border-color: var(--ion-color-danger);
    }
    &.ion-focused.ion-valid {
      --border-color: var(--ion-color-primary);
    }
    &.select-expanded {
      --border-color: var(--highlight-color);
    }
  }
}

// Summarized Form Inputs
ion-input.summarized-input {
  &.legacy-input.sc-ion-input-md-h, 
  &.native-input[disabled].sc-ion-input-md, 
  &.input-disabled.sc-ion-input-md-h {
    opacity: 1;

    & label.input-wrapper > div.label-text-wrapper > div.label-text {
      font-size: 16px !important;
    }
  }

  & label.input-wrapper > div.label-text-wrapper > div.label-text {
    color: var(--ion-color-dark);
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px
  }

  & label.input-wrapper > div.native-wrapper > input.native-input {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
  }
}

ion-checkbox.summarized-checkbox {
  --size: 16px;
  margin-bottom: 12px;
}

ion-checkbox.disabled-summarized-checkbox {
  --size: 16px;
  margin-bottom: 12px; 

  &::part(container) {
    opacity: 0.5;
  }
}

div.input-wrapper,
section.input-wrapper {
  &:has(.input-label-placement-end),
  &:has(.input-label-placement-fixed),
  &:has(.input-label-placement-floating),
  &:has(.input-label-placement-start),
  &:has(.textarea-label-placement-end),
  &:has(.textarea-label-placement-fixed),
  &:has(.textarea-label-placement-floating),
  &:has(.textarea-label-placement-start) {
    & [slot="start"],
    & [slot="end"] {
      top: var(--slot-top, 16px);

      &[size="small"] {
        top: var(--slot-top, 18px);
      }
      &[size="large"] {
        top: var(--slot-top, 12px);
      }
    }
  }
}

ion-item.item-fill-outline {
  --inner-border-width: 0;
}
ion-item.item-fill-outline ion-select::part(container) {
  width: 100%;
}

.beta {
  width: fit-content;
  background-color: var(--ion-color-blue-200);
  border-radius: 4px;
  color: var(--ion-color-primary);
  position: absolute;

  & .skipped {
  left: 148px;
  }
}

input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

// *********************** for screen readers only ***************************//
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.cdk-virtual-scroll-viewport--booking-history .cdk-virtual-scroll-content-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100% !important;
}

/* *********************************************************
 * GOOGLE MAPS
 * ********************************************************/
.GMAMP-maps-pin-view { transition: all 0.25s linear; }
.GMAMP-maps-pin-view:hover { transform: scale(1.5); }

/* hide close button in info-window */
button.gm-ui-hover-effect {
  visibility: hidden;
}

/* clear the paddings  */
.gm-style .gm-style-iw-c,
.gm-style-iw.gm-style-iw-c {
  border-radius: 0;
  padding: 0 !important;
  outline: none;
}

/* remove the white space */
.gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}

/* remove the close button */
.gm-style-iw-chr {
  display: none;
}

google-map {
  width: 100%;
  height: 100%;

  & .btn-search-map {
    margin-top: 12px;
  }
}

@media (min-width: 0px) and (max-width: 525px) and (pointer: fine) {
  google-map .btn-search-map {
    margin-top: calc(env(safe-area-inset-top) + 65px);
  }
}
@media (min-width: 765px) and (max-width: 820px) {
  google-map .btn-search-map {
    margin-top: calc(env(safe-area-inset-top) + 65px);
  }
}

// V2 Reusable styles: 
@layer components {
  .v2-card {
    @apply border-[1px] border-grey-300 bg-grey-50;
  }

  .v2-info-card {
    @apply border-[1px] border-grey-300 bg-white p-8 gap-4;
  }
}
