:root {
--ion-color-primary: #003dff;
--ion-color-primary-rgb: 0, 61, 255;
--ion-color-primary-contrast: #ffffff;
--ion-color-primary-contrast-rgb: 255, 255, 255;
--ion-color-secondary: #dbff00;
--ion-color-secondary-rgb: 219, 255, 0;
--ion-color-secondary-contrast: #000000;
--ion-color-secondary-contrast-rgb: 0, 0, 0;
--ion-color-tertiary: #003dff;
--ion-color-tertiary-rgb: 0, 61, 255;
--ion-color-tertiary-contrast: #ffffff;
--ion-color-tertiary-contrast-rgb: 255, 255, 255;
--ion-color-success: #3e9e11;
--ion-color-success-rgb: 62, 158, 17;
--ion-color-success-contrast: #ffffff;
--ion-color-success-contrast-rgb: 255, 255, 255;
--ion-color-danger: #da1205;
--ion-color-danger-rgb: 218, 18, 5;
--ion-color-danger-contrast: #ffffff;
--ion-color-danger-contrast-rgb: 255, 255, 255;
--ion-color-dark: #000000;
--ion-color-dark-rgb: 0, 0, 0;
--ion-color-dark-contrast: #ffffff;
--ion-color-dark-contrast-rgb: 255, 255, 255;
--ion-color-medium: #6f6f6f;
--ion-color-medium-rgb: 111, 111, 111;
--ion-color-medium-contrast: #ffffff;
--ion-color-medium-contrast-rgb: 255, 255, 255;
--ion-color-light: #ffffff;
--ion-color-light-rgb: 255, 255, 255;
--ion-color-light-contrast: #000000;
--ion-color-light-contrast-rgb: 0, 0, 0;
--ion-color-warning: #ffa400;
--ion-color-warning-rgb: 255, 164, 0;
--ion-color-warning-contrast: #ffffff;
--ion-color-warning-contrast-rgb: 255, 255, 255;
--ion-color-white: #ffffff;
--ion-color-white-rgb: 255, 255, 255;
--ion-color-white-contrast: #000000;
--ion-color-white-contrast-rgb: 0, 0, 0;
--ion-color-primary-shade: #0030df;
--ion-color-secondary-shade: #cee504;
--ion-color-tertiary-shade: #0030df;
--ion-color-success-shade: #245515;
--ion-color-danger-shade: #b20b00;
--ion-color-dark-shade: #000000;
--ion-color-medium-shade: #5b5959;
--ion-color-light-shade: #ffffff;
--ion-color-warning-shade: #bf7b1d;
--ion-color-white-shade: #ffffff;
--ion-color-primary-subtle: #d2ddff;
--ion-color-secondary-subtle: #f4ffb4;
--ion-color-tertiary-subtle: #bacaff;
--ion-color-success-subtle: #f2ffeb;
--ion-color-danger-subtle: #ffeeeb;
--ion-color-dark-subtle: #fafaf7;
--ion-color-medium-subtle: #f6f6ec;
--ion-color-light-subtle: #ffffff;
--ion-color-warning-subtle: #f5ece1;
--ion-color-white-subtle: #ffffff;
--ion-color-primary-tint: #345cfb;
--ion-color-secondary-tint: #eeff89;
--ion-color-tertiary-tint: #003dff;
--ion-color-success-tint: #87c36b;
--ion-color-danger-tint: #ffa38e;
--ion-color-dark-tint: #92927b;
--ion-color-medium-tint: #92927b;
--ion-color-light-tint: #d6d6bf;
--ion-color-warning-tint: #ffbe61;
--ion-color-white-tint: #d6d6bf;
}