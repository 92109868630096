@use './figma-colors.scss';
@use './figma-shades.scss';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  .ion-color-white {
    --ion-color-base: #ffffff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #e0e0e0;
    --ion-color-tint: #ffffff;
  }

  --ion-grid-width-sm: 100%;
  --ion-grid-width-xl: 992px;

  --ion-font-family: 'NeuePlakText', sans-serif;
  

  --ion-color-fb: #3b5999;
  --ion-color-fb-rgb: 59, 89, 153;
  --ion-color-fb-contrast: #ffffff;
  --ion-color-fb-contrast-rgb: 255, 255, 255;
  --ion-color-fb-shade: #344e87;
  --ion-color-fb-tint: #4f6aa3;

  .ion-color-fb {
    --ion-color-base: var(--ion-color-fb);
    --ion-color-base-rgb: var(--ion-color-fb-rgb);
    --ion-color-contrast: var(--ion-color-fb-contrast);
    --ion-color-contrast-rgb: var(--ion-color-fb-contrast-rgb);
    --ion-color-shade: var(--ion-color-fb-shade);
    --ion-color-tint: var(--ion-color-fb-tint);
  }

  --ion-color-twitter: #1da1f2;
  --ion-color-twitter-rgb: 85, 172, 238;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #4b97d1;
  --ion-color-twitter-tint: #66b4f0;

  .ion-color-twitter {
    --ion-color-base: var(--ion-color-twitter);
    --ion-color-base-rgb: var(--ion-color-twitter-rgb);
    --ion-color-contrast: var(--ion-color-twitter-contrast);
    --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
    --ion-color-shade: var(--ion-color-twitter-shade);
    --ion-color-tint: var(--ion-color-twitter-tint);
  }

  --ion-color-whatsapp: #25d366;
  --ion-color-whatsapp-rgb: 37, 211, 102;
  --ion-color-whatsapp-contrast: #ffffff;
  --ion-color-whatsapp-contrast-rgb: 255, 255, 255;
  --ion-color-whatsapp-shade: #21ba5a;
  --ion-color-whatsapp-tint: #3bd775;

  .ion-color-whatsapp {
    --ion-color-base: var(--ion-color-whatsapp);
    --ion-color-base-rgb: var(--ion-color-whatsapp-rgb);
    --ion-color-contrast: var(--ion-color-whatsapp-contrast);
    --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb);
    --ion-color-shade: var(--ion-color-whatsapp-shade);
    --ion-color-tint: var(--ion-color-whatsapp-tint);
  }

  /** google **/

  --ion-color-google: #db4437;
  --ion-color-google-rgb: 219, 68, 55;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #c13c30;
  --ion-color-google-tint: #df574b;

  .ion-color-google {
    --ion-color-base: #db4437;
    --ion-color-base-rgb: 219, 68, 55;
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #c13c30;
    --ion-color-tint: #df574b;
  }

  ion-button {
    outline: none !important;
  }

  ion-spinner {
    color: var(--ion-color-contrast, var(--ion-color-primary-contrast));
  }

  ion-item {
    --highlight-color-invalid: var(--border-color);
    --highlight-color-valid: var(--border-color);

    &.item-input {
      --border-radius: 6px;
    }
  }

  ion-chip {
    line-height: 1.5;
  }

  ion-loading {
    --spinner-color: var(--ion-color-medium);
  }

  ion-card,
  app-place-card {
    outline: none !important;
  }

  @media (min-width: 992px) {
    ion-modal {
      --border-radius: 6px;
      --border-color: var(--ion-color-light-tint);
      --border-style: solid;
      --border-width: 3px;
    }
  }
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  --ion-color-primary: $primary;
  --ion-color-primary-rgb: $primary-rgb;
  --ion-color-primary-contrast: $primary-contrast;
  --ion-color-primary-contrast-rgb: $primary-contrast-rgb;
  --ion-color-primary-shade: $primary-shade;
  --ion-color-primary-tint: $primary-tint;

  --ion-color-secondary: $secondary;
  --ion-color-secondary-rgb: $secondary-rgb;
  --ion-color-secondary-contrast: $secondary-contrast;
  --ion-color-secondary-contrast-rgb: $secondary-contrast-rgb;
  --ion-color-secondary-shade: $secondary-shade;
  --ion-color-secondary-tint: $secondary-tint;

  --ion-color-dark: $dark;
  --ion-color-dark-rgb: $dark-rgb;
  --ion-color-dark-contrast: $dark-contrast;
  --ion-color-dark-contrast-rgb: $dark-contrast-rgb;
  --ion-color-dark-shade: $dark-shade;
  --ion-color-dark-tint: $dark-tint;

  --ion-color-medium: $medium;
  --ion-color-medium-rgb: $medium-rgb;
  --ion-color-medium-contrast: $medium-contrast;
  --ion-color-medium-contrast-rgb: $medium-contrast-rgb;
  --ion-color-medium-shade: $medium-shade;
  --ion-color-medium-tint: $medium-tint;

  --ion-color-light: $light;
  --ion-color-light-rgb: $light-rgb;
  --ion-color-light-contrast: $light-contrast;
  --ion-color-light-contrast-rgb: $light-contrast-rgb;
  --ion-color-light-shade: $light-shade;
  --ion-color-light-tint: $light-tint;

  --ion-color-white: $white;
  --ion-color-white-rgb: $white-rgb;
  --ion-color-white-contrast: $white-contrast;
  --ion-color-white-contrast-rgb: $white-contrast-rgb;
  --ion-color-white-shade: $white-shade;
  --ion-color-white-tint: $white-tint;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}