:root {
  --ion-color-blue-050: #e6ecff;
  --ion-color-blue-100: #ccd8ff;
  --ion-color-blue-200: #b3c5ff;
  --ion-color-blue-300: #99b1ff;
  --ion-color-blue-400: #668bff;
  --ion-color-blue-500: #003dff;
  --ion-color-blue-600: #002599;
  --ion-color-blue-700: #001866;
  --ion-color-blue-800: #00124d;
  --ion-color-blue-900: #000c33;
  --ion-color-blue-950: #000619;
  --ion-color-yellow-050: #fbffe6;
  --ion-color-yellow-100: #f8ffcc;
  --ion-color-yellow-200: #f4ffb3;
  --ion-color-yellow-300: #f1ff99;
  --ion-color-yellow-400: #e9ff66;
  --ion-color-yellow-500: #dbff00;
  --ion-color-yellow-600: #839900;
  --ion-color-yellow-700: #586600;
  --ion-color-yellow-800: #424d00;
  --ion-color-yellow-900: #2c3300;
  --ion-color-yellow-950: #161900;
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-red-050: #ffe9e6;
  --ion-color-red-100: #ffd3cc;
  --ion-color-red-200: #ffbdb3;
  --ion-color-red-300: #ffa799;
  --ion-color-red-400: #ff7c66;
  --ion-color-red-500: #ff2400;
  --ion-color-red-600: #991600;
  --ion-color-red-700: #660e00;
  --ion-color-red-800: #4d0b00;
  --ion-color-red-900: #330700;
  --ion-color-red-950: #190400;
  --ion-color-green-050: #ecf5e7;
  --ion-color-green-100: #d8eccf;
  --ion-color-green-200: #c5e2b8;
  --ion-color-green-300: #b2d8a0;
  --ion-color-green-400: #8bc570;
  --ion-color-green-500: #3e9e11;
  --ion-color-green-600: #255f0a;
  --ion-color-green-700: #193f07;
  --ion-color-green-800: #132f05;
  --ion-color-green-900: #0c2003;
  --ion-color-green-950: #061002;
  --ion-color-grey-050: #f4f4f2;
  --ion-color-grey-100: #e9e9e5;
  --ion-color-grey-200: #deded7;
  --ion-color-grey-300: #d3d3ca;
  --ion-color-grey-400: #bebeb0;
  --ion-color-grey-500: #92927b;
  --ion-color-grey-600: #58584a;
  --ion-color-grey-700: #3a3a31;
  --ion-color-grey-800: #2c2c25;
  --ion-color-grey-900: #1d1d19;
  --ion-color-grey-950: #0f0f0c;
  --ion-color-orange-050: #fdf5e6;
  --ion-color-orange-100: #fbebcd;
  --ion-color-orange-200: #f9e0b4;
  --ion-color-orange-300: #f7d69b;
  --ion-color-orange-400: #f2c26a;
  --ion-color-orange-500: #ea9906;
  --ion-color-orange-600: #8c5c04;
  --ion-color-orange-700: #5e3d02;
  --ion-color-orange-800: #462e02;
  --ion-color-orange-900: #2f1f01;
  --ion-color-orange-950: #170f01;
}